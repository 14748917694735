<route>
{
  "meta": {
    "auth": "orderDetail"
  }
}
</route>

<template>
    <div>
    <el-card class="container">
      <div class="detail-title">基本信息</div>
      <el-form inline>
        <el-form-item v-for="(item, index) in baseInfo" :key="index" style="width: 40%" :label="item.label">
          <div class="value">
            <template v-if="item.label === '购买人（ID）'">
              {{ orderDetail.bmOrderDTO.memberName }}
              <div class="btn" @click="$router.push(`/user/particulars?id=${orderDetail.bmOrderDTO.memberId}`)">
                查看用户信息>>
              </div>
            </template>

            <template v-else-if="item.label === '订单状态'">
              {{ orderDetail.expressOrderStatus | expressStatus  }}
            </template>
            <template v-else-if="item.label === '创建时间'">
              {{item.value | formatTime }}
            </template>
            <template v-else-if="item.label === '购买方式'">
              {{ item.value === 0 ? '普通' : '特殊' }}
            </template>
          

            <template v-else>
              {{ item.value }}
            </template>
          </div>
        </el-form-item>
      </el-form>
 

      <div class="detail-title">商品信息</div>
      <el-table :data="orderDetail.expressOrderDTOList" class="table">
        <el-table-column
          v-for="col in Object.keys(productInfo.label)"
          :key="col"
          :prop="col"
          :label="productInfo.label[col]"
        >
          <template slot-scope="{ row }">
            <div class="product" v-if="col === 'product'">
              <img :src="row.product.cover | thumbSmall" />
              <div class="right">
                <div>商品ID：{{ row.productId }}</div>
                <div class="name">{{ row.productName }}</div>
              </div>
            </div>
            <template slot-scope="{ row }" v-else-if="col === 'price'">￥{{ row.price }}</template>
            <template slot-scope="{ row }" v-else-if="col === 'buyType'">普通</template>

            
            <template slot-scope="{ row }" v-else>{{ row[col] }}</template>
          </template>
        </el-table-column>
      </el-table>

      <div class="detail-title">金额信息</div>
      <el-form>
        <el-row>
        
          <el-col :span="6">
            <el-form-item label="订单总额">
              <div class="value">￥{{ orderDetail.bmOrderDTO.totalMoney }}</div>
            </el-form-item>
          </el-col>

             <el-col  :span="6">
            <el-form-item label="优惠金额">
              <div class="value">￥{{  orderDetail.bmOrderDTO.discountMoney }}</div>
            </el-form-item>
          </el-col>
             <el-col  :span="6">
            <el-form-item label="实付金额">
              <div class="value">￥{{ orderDetail.bmOrderDTO.actualPayMoney  }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>


        <div class="detail-title">收件信息</div>
        <el-form>
          <el-form-item style="width: 60%" label="收件人名称">
            <div class="value">{{orderDetail.expressOrderAddressDTO.name}}</div>
          </el-form-item>
          <el-form-item style="width: 60%" label="收件人电话">
            <div class="value">{{orderDetail.expressOrderAddressDTO.mobile}}</div>
          </el-form-item>

                   <el-form-item style="width: 60%" label="收货省市区">
            <div class="value">{{orderDetail.expressOrderAddressDTO.area}}</div>
          </el-form-item>
          <el-form-item style="width: 60%" label="收货地址">
            <div class="value">{{orderDetail.expressOrderAddressDTO.detailedAddress}}</div>
          </el-form-item>
        </el-form>

        <div class="detail-title">物流信息</div>
        <div class="skuCard" v-for="(item, index) in orderDetail.expressParcelDTOList" :key="'item' + index">
          <div @click="gotoDetail(index)" class="item">
            包裹：{{ index + 1 }}  <i style="margin-left:30px" class="el-icon-d-arrow-right" ></i></br>
            {{ item.expressCorporationName }}：{{ item.expressNumber }} </br>包裹状态：{{ item.parcelStatus | parcelStatus  }}
          </div>
        </div>

    </el-card>
  </div>
</template>
<script>
import orderDetail from '@/component/orderDetail'
export default {
  components: { orderDetail },
  filters: {
    parcelStatus(val) {
      switch (val) {
        case 1:
          return '已揽收'
          break
        case 201:
          return '到达派件城市'
          break
        case 202:
          return '派件中'
          break
        case 211:
          return '已放入快递柜或驿站'
          break
        case 3:
          return '已签收'
          break
        case 301:
          return '正常签收'
          break
        case 302:
          return '派件异常后最终签收'
          break
        case 304:
          return '代收签收'
          break
        case 311:
          return '快递柜或驿站签收'
          break
        case 4:
          return '问题件'
          break
        case 401:
          return '发货无信息'
          break
        case 402:
          return '超时未签收'
          break
        case 403:
          return '超时未更新'
          break
        case 404:
          return '拒收(退件)'
          break
        case 405:
          return '派件异常'
          break
        case 406:
          return '退货签收'
          break
        case 407:
          return '退货未签收'
          break
        case 412:
          return '快递柜或驿站超时未取'
          break
        default:
          return '没有匹配到'
          break
      }
    },

    expressStatus(val) {
      switch (val) {
        case 0:
          return '待支付'
          break
        case 1:
          return '待发货'
          break
        case 2:
          return '已发货'
          break
        case 3:
          return '已收货'
          break
        case 4:
          return '已评价'
          break
        case 5:
          return '已关闭'
          break
        case 6:
          return '交易完成'
          break
        case 7:
          return '交易取消'
          break
        default:
          return '没有匹配到'
          break
      }
    }
  },
  data() {
    return {
      expressList: [
        { name: '京东快递', number: 'JDV342534535', state: '已收货' },
        { name: '顺丰快递', number: 'FDG3453534534', state: '已收货' }
      ],
      orderDetail: {
        orderRefund: {},
        checkCodeList: [],
        orderRefundItemList: [],
        orderRefundList: []
      },
      //基本信息
      baseInfo: {
        id: {
          label: '订单号',
          value: ''
        },
        memberId: {
          label: '购买人（ID）',
          value: ''
        },
        orderStatus: {
          label: '订单状态',
          value: '待消费'
        },
        memberMobile: {
          label: '购买人电话',
          value: ''
        },
        buyType: {
          label: '购买方式',
          value: '普通'
        },
        memberNotes: {
          label: '订单备注',
          value: ''
        },

        orderTime: {
          label: '创建时间',
          value: ''
        }
      },

      distribution: {
        label: {
          distributionSource: '收益类型',
          distributionMemberNickname: '分销用户',
          incomeAmount: '分销佣金',
          distributionStatus: '分销状态',
          recordedTime: '入账时间'
        },
        data: []
      },
      //商品信息

      productInfo: {
        label: {
          productName: '商品信息',
          businessName: '商家',
          buyType: '购买方式',
          skuName: '款式',
          optionsName: '规格',
          skuPrice: '款式单价',
          skuNum: '数量'
        },
        data: []
      },
      //金额信息
      moneyInfo: {
        label: {
          totalMoney: '订单总额',
          totalDiscountMoney: '优惠金额',
          actualPayMoney: '实付金额'
        },
        value: {
          totalMoney: 352,
          totalDiscountMoney: 10,
          actualPayMoney: 342
        }
      },
      //核销码信息
      checkCodeInfo: {
        status: {
          1: '未核销',
          2: '已核销',
          3: '已过期',
          4: '退款中',
          5: '已退款'
        },
        label: { id: '子订单号', checkCode: '核销码', checkStatus: '状态', time: '核销码信息' },
        data: []
      },
      allActualRefundMoney: 0,
      refund: {
        type: 1, //退款类型
        input: 2, //退款比例
        deduct: 0 //退款金额
      },
      refundReason: '',
      rejectReasonList: [],
      rejectReason: {
        selected: 1,
        list: [
          {
            label: '此商品不能退款',
            value: 1
          },
          {
            label: '此商品已经核销',
            value: 2
          },
          {
            label: '微信故障，暂不可退款',
            value: 3
          }
        ]
      },
      selected: [], //选择要退款的核销码-detail
      acting: false
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    gotoDetail(index) {
      sessionStorage.orderDetail = JSON.stringify(this.orderDetail)
      this.$router.push({
        path: '/order/express/detail',
        query: {
          index: index
        }
      })
    },
    getDetail() {
      this.$http
        .get('/boom-mix-biz-service/sysAdmin/order/express/detail', { params: { bmOrderId: this.$route.query.id } })
        .then(res => {
          res.expressOrderDTOList = res.expressOrderDTOList.map(item => {
            item.businessName = res.bmOrderDTO.businessName
            return item
          })
          this.orderDetail = res
          Object.keys(res.bmOrderDTO).map(key => {
            if (this.baseInfo[key]) {
              this.baseInfo[key].value = res.bmOrderDTO[key]
            }
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: @container-margin;
  padding: 20px;

  .detail-title {
    margin-bottom: 10px;
  }

  .value {
    font-weight: bold;

    .btn {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #e7141a;
      margin-left: 15px;
    }
  }

  .table {
    margin-bottom: 25px;

    .product {
      display: flex;
      align-items: center;

      img {
        border-radius: 5px;
        width: 60px;
        height: 60px;
        object-fit: cover;
      }

      .right {
        flex: 1;
        overflow: hidden;
        margin-left: 10px;

        .name {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .checkCodeNum {
    margin-bottom: 30px;

    .item {
      widows: 100px;
      text-align: center;
      display: inline-block;
      margin-right: 30px;

      .value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
      }

      .label {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .tip {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 20px;
  }

  .input {
    width: 150px;
  }

  .action {
    margin-top: 50px;
    .refundReason {
      margin: 20px;
      .el-input {
        width: 250px;
      }
    }

    .btn {
      border-radius: 30px;
      background: @color-primary-select;
      color: #ffffff;
      padding: 15px 66px;
      margin-right: 20px;
      border: 1px solid @color-primary-select;
      font-size: 15px;
    }

    .outline-btn {
      background: #ffffff;
      color: @color-primary-select;
    }
  }
}
.deduct {
  display: inline-block;
  background: #f5f7fa;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 20px;
}
/deep/.current {
  background: rgba(255, 0, 0, 0.05);
}

/deep/.el-form-item__label {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: left;
}
.skuCard {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background: var(--background);
  width: 500px;
  padding: 10px;
  margin-top: 20px;
  .code {
    font-weight: bold;
  }
  .item {
    margin-top: 16px;
    .name {
      margin-top: 8px;
    }
  }
}
</style>
